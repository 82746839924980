@charset "utf-8";
@import "bootstrap/bootstrap";

$montserrat-font-path: "/fonts";

@font-face {
  font-family: 'Montserrat';
  src: url('#{$montserrat-font-path}/montserrat-regular-webfont.eot');
  src: url('#{$montserrat-font-path}/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$montserrat-font-path}/montserrat-regular-webfont.woff2') format('woff2'),
       url('#{$montserrat-font-path}/montserrat-regular-webfont.woff') format('woff'),
       url('#{$montserrat-font-path}/montserrat-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html {
  position: relative;
  min-height: 100%;
}

body {
	color: #333333;
	background: #ffffff;  
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-bottom: 180px;
}

#header-1 {
	background-color: #193441;
}

#header-1 .main-nav .navbar-toggle:focus {
	outline: none;
}

#header-1 .main-nav .nav .nav-item a {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;
}

#header-1 .main-nav ul li.nav-item {
	margin-bottom: 0px;

}

#header-1 .main-nav .nav .nav-item.active a {
	color: #3498DB;
	background: none;
}

#header-1 .main-nav .nav .nav-item a:hover {
	color: #3498DB;
	background: none;
}

#header-1 .main-nav .nav .nav-item a:focus {
	outline: none;
	background: none;
}

#header-1 .main-nav .nav .nav-item a:active {
	outline: none;
	background: none;
}

#header-1 .main-nav .nav .nav-item.active {
	color: #333;
}

#header-1 .main-nav .nav .dropdown-menu {
	background: #3E606F;
}

#header-1 .main-nav .nav .dropdown-menu a {
	padding: 8px 20px;
	color: #fff;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
}

#header-1 .main-nav .nav .dropdown-menu a:hover {
	background: #193441;
	color: #fff;
}

#header-1 .main-nav .navbar-toggler-icon {
	color: #fff;
}

#header-1 .brand-img {
	max-width: 150px;
	padding: 5px 0;
}

/** MAIN PAGE **/
.mainpagegradient {
	background: linear-gradient(180deg, #193441, #193441 2%, #76D6FF);
	 -webkit-background-size: cover;
	 -moz-background-size: cover;
	 -o-background-size: cover;
	 background-size: cover;
}

.mainpage {
	color: white;
	text-shadow: 0 1px 0 black;
}

.mainpage h1 {
	text-transform: uppercase;
	font-weight: 300;
}

/** TYPOGRAPHY **/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif;
	line-height: 1.1;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
	color: #e5e5e5;
}

h1, h2, h3 {
	font-weight: 300;
}

h4, h5, h6 {
	font-weight: 400;
}

h1, .h1 { font-size: 41px; }
h2, .h2 { font-size: 33px; }
h3, .h3 { font-size: 25px; }
h4, .h4 { font-size: 22px; }
h5, .h5 { font-size: 18px; }
h6, .h6 { font-size: 16px; }

p {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 15px;
	line-height: 1.6;
	margin: 15px 0;
	color: #515157;
}

table {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	line-height: 1.6;
	color: #515157;
}

.lead {
	margin-bottom: 30px;
	font-size: 20px;
	line-height: 1.4;
	font-weight: 300;
}

small, .small {
	font-size: 80%;
	line-height: 2;
}

em { color: #ff6600; }

/* LINKS */

a { color: #13937a; }

.mainpage a { color: white;}
#contact a { color: white;}
#footer a {color: white;}

a:hover {
	color: #16a085;
	text-decoration: none;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* LISTS */

ol {
	position: relative;
	padding-left: 22px;
}

ul {
    list-style: none;
	position: relative;
	padding-left: 22px;
}

ul li,
ol li {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #515157;
	margin-bottom: 5px;
}

ul.circle li:before {
    content: '○';
    font-size: 12px;
    color: #16a085;
    padding-top: 2px;
    padding-right: 1em;
	margin-left: -20px;
	position: relative;
}

/* TITLES */

.underlined-title {
	text-align: center;
}

.underlined-title h1 {
	color: #2c3e50;
}

.underlined-title h2 {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 700;
	color: #95a5a6;
}

.underlined-title hr {
	width: 10%;
	border-width: 2px;
	border-color: #16a085;
}

/* TABS */

.nav-tabs {
	margin: 0 auto;
}

.nav-tabs > li {
	z-index: 2;
	float: none;
	display: inline-block;
}

.nav-tabs > li > a {
	padding: 16px 25px 12px;
	font-size: 14px;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
	color: #737c85;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	border: none !important;
	border-bottom: 4px solid transparent !important;
}

.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus {
	background-color: transparent;
	border-bottom: 4px solid #85d6de !important;
}

.nav > li > a:hover,
.nav > li > a:focus {
	outline: 0;
}

.tab-content {
	padding-left: 0;
	padding-right: 0;
	border: none;
}


/* FOOTER */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px;
  font-size: 14px; /* Set the fixed height of the footer here */
/*  line-height: 60px;  Vertically center the text there 
*//*  background-color: #f5f5f5;*/
}


/* MISC */

.customcaption {
	text-align: center !important;
	font-size: 85%;
  	font-weight: 400;
  	line-height: 1;
}

/* PADDING, MARGIN & ALIGNMENT */

.min-height-100px { min-height: 100px; }
.min-height-200px { min-height: 200px; }
.min-height-300px { min-height: 300px; }
.min-height-400px { min-height: 400px; }
.min-height-500px { min-height: 500px; }
.min-height-600px { min-height: 600px; }
.min-height-700px { min-height: 700px; }
.min-height-800px { min-height: 800px; }

/* SVG */
.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    /*padding-bottom: 100%;*/ /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
}
.svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.axis {
	font: 15px sans-serif;
}

.bar {
	stroke: grey;
	stroke-width: 0.5px;
}

.legend {
	font: 15px sans-serif;
}

/* LEGAL */
.legal ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.legal li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  font-weight: bold;
}

.legal li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
}

.legal li li {
    margin: 0;
    font-weight: normal;
}

.legal li li:before {
    content: counters(item, ".") " ";
}

